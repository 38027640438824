<template>
    <div>
        <p v-if="sending" class="sending">Sending your bid to blockhain...</p>
        <trading-vue :data="graph"
                     :width="this.width"
                     :toolbar="true"
                     v-loading="loading"
                     :element-loading-text="loadingText"
                     element-loading-background="rgba(0, 0, 0, 0.8)"
                     :height="this.height"
                     :overlays="overlays"
                     :color-back="colors.colorBack"
                     :color-grid="colors.colorGrid"
                     :timezone="1"
                     :color-text="colors.colorText"
                     titleTxt="EURUSD"
                     colorTitle="#ffffff"
                     colorCandleUp="#26a69a"
                     colorCandleDw="#d3302f"
                     ref="tradingVue">
        </trading-vue>
    </div>
</template>


<script>
import { DataCube, TradingVue } from 'trading-vue-js';
import { mapState } from 'vuex';
import Web3 from 'web3';

let web3 = new Web3(window.ethereum);


export default {
    name: 'MainView',
    components: { TradingVue },
    data() {
        return {
            graphInfo: {
                ts: 0,
                open: 0,
                high: 0,
                low: 0,
                close: 0,
                volume: 0,
            },
            previousTick: 0,
            width: window.innerWidth - 300,
            height: window.innerHeight - 50,
            colors: {
                colorBack: '#131722',
                colorGrid: '#20232e',
                colorText: '#c6d9fe',
            },
            overlays: [],
            graph: new DataCube({
                chart: {
                    type: 'Candles',
                    tf: '60000',
                    id: 'chart1',
                    grid: {
                        logScale: false,
                        height: 8,
                    },
                    settings: {},
                    data: [],
                    indexBased: true,
                },
                //линейный график в темплейте со свечами
                onchart: [
                    {
                        name: 'EMA, 25',
                        type: 'EMA',
                        data: [],
                    },
                ],
                //линейный график в нижнем темплейте
                offchart: [
                    {
                        name: 'RSI, 20',
                        type: 'RSI',
                        data: [],
                        grid: {
                            logScale: false,
                            height: 1,
                        },
                    },

                ],
            }),

        };
    },
    computed: {
        ...mapState([
            'bid',
            'graphLength',
            'loading',
            'loadingText',
            'sending',
            'userWalletAddress',
            'contractAddress',
            'contractData',
            'userWalletAddress',
            'historyHashDeal',
            'historyHashTransfer'
]),
    },
    watch: {
        bid(val) {
            //отслеживание события клика кнопки ставки up или down из сайдабар
            if (val.fromButton) {
                this.clickButton(val);
            }
        },
        /** проверка на наличие элементов массива в графике */
        'graph.data.chart.data.length'(val) {
            if (val && !this.graphLength) {
                this.$store.commit('setGraphLength', val);
                this.$store.commit('setLoading', false);
                this.getContractInfo();
            }
            if (!val) {
                this.$store.commit('setGraphLength', val);
            }
        }
    },
    methods: {
        onResize(event) {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
        },
        getContractInfo() {
            let contract = new web3.eth.Contract(this.contractData, this.contractAddress,
                { from: this.userWalletAddress });
            contract.methods.getMyTrades().call({ from: this.userWalletAddress }, (err, res) => {
                let result = {};
                //создание объекта результата только из строковых ключей массива
                for (let x of Object.keys(res)) {
                    if (!Number.isInteger(+x)) {
                        result[x] = res[x];
                    }
                }
                this.$store.commit('setBid', {
                    bidInfo: result
                });
                //если текущее время меньше времени окончания сделки
                if (res.whenTradeEnds > Math.round(Date.now()/1000)*1000/1000) {
                    if (res.tradeToBuy) {
                        this.drawLine('Up ⬆️','green');
                        this.drawLineEnd();
                        // this.drawHorizontalLine('⬆️','green');
                    } else {
                        this.drawLine('🔻','red');
                        this.drawLineEnd();
                        // this.drawHorizontalLine('🔻','red');
                    }
                    console.log('timeToEnd', this.bid.bidInfo.whenTradeEnds * 1000, Date.now());
                    let timeToEnd = (this.bid.bidInfo.whenTradeEnds * 1000 - Date.now())/1000;
                    console.log('timeToEnd', timeToEnd);
                    this.$store.dispatch('setTimeCounter', timeToEnd);
                }
            });
        },
        convertDataToChart(data) {
            let dataItem = '';
            if (data?.data && data?.data.includes('{')) {
                dataItem = JSON.parse(data?.data);
            }

            //на выходные менять на BTCUSD
            //if (dataItem?.symbol == 'BTCUSD') {
              if (dataItem?.symbol == 'EURUSD') {

                dataItem.ts = Math.ceil(parseInt(dataItem.ts));
                this.graphInfo.ts = dataItem.ts;
                if(dataItem.bid > 0) {
                if ((this.previousTick + 500) < dataItem.ts) {
                    this.graphInfo.close = dataItem.bid;
                    this.previousTick = dataItem.ts;
                    //С ТИКАМИ НУЖНО РАЗОБРАТЬСЯ (ПОКА ЗАГЛУШКА - ДУБЛИ НЕЛЬЗЯ КИДАТЬ)
                    //dataItem.ts = this.previousTick;
                    // this.previousTick = this.previousTick + 1;
                    //console.log(this.previousTick);
                    dataItem.dd = dataItem.ask;

                    this.$nextTick(() => {

                        this.$store.commit('setGraphNow', dataItem.bid);
                        dataItem.bid = this.graphInfo.low;
                        dataItem.ask = this.graphInfo.high;
                        //dataItem.mid = this.graphInfo.close;
                        dataItem.volume = this.graphInfo.volume;

                        if (this.graphInfo.open != 0 && this.graphInfo.high != 0 && this.graphInfo.low != 0 &&
                            this.graphInfo.close != 0) {
                            this.graph.data.chart.data.push(Object.values(this.graphInfo).slice(0));
                            this.graph.data.onchart[0].data.push([Object.values(dataItem)[1], dataItem.mid]);
                            this.graph.data.offchart[0].data.push([Object.values(dataItem)[1], dataItem.mid]);
                            //this.$refs.tradingVue?.resetChart();// ???????????????
                            this.graphInfo.open = 0;
                            this.graphInfo.close = 0;
                            this.graphInfo.volume = 0;
                            this.graphInfo.low = 0;
                            this.graphInfo.high = 0;
                        }
                    });
                } else {

                    if (this.graphInfo.high == 0 && this.graphInfo.low == 0) {
                        this.graphInfo.high = dataItem.bid;
                        this.graphInfo.low = dataItem.bid;
                    }
                    if (this.graphInfo.open == 0) {
                        this.graphInfo.open = dataItem.bid;
                    }
                    if (this.graphInfo.high < dataItem.bid) {
                        this.graphInfo.high = dataItem.bid;
                    }
                    if (this.graphInfo.low > dataItem.bid) {
                        this.graphInfo.low = dataItem.bid;
                    }
                }

              }
            }
        },
        webSocketStart() {
            const reconnectInterval = 1000;
            let _self = this;
            this.$store.commit('setLoading', true);
            this.$store.commit('setLoadingText', 'Connecting to realtime market data...');
            console.log('Starting connection to WebSocket Server');
            let connect = function() {
                _self.connection = new WebSocket('wss://marketdata.tradermade.com/feedadv');

                _self.connection.onmessage = function(event) {
                    // console.log(event);
                };

                _self.connection.onopen = function(event) {
                    //console.log('we are sending request');

                    //на выходные менять на BTCUSD
                    // _self.connection.send('{"userKey":"wshvaa-4l71kCU32cMsw", "symbol":"EURUSD"}');
                    _self.connection.send('{"userKey":"ws57T6gYcZwrlN5oJ60Q", "symbol":"EURUSD"}');
                };

                _self.connection.onclose = function() {
                    //console.log('socket close : will reconnect in ' + reconnectInterval);
                    setTimeout(connect, reconnectInterval);
                };

                _self.connection.onmessage = function incoming(data) {
                    _self.graphInfo.volume++;
                    _self.convertDataToChart(data);
                };
            };

            connect();
        },

        /** click button from sideBar*/
        clickButton(val) {
            if (val.up) {
                this.drawLine('Up ⬆️','green');
                this.drawLineEnd();
                this.drawHorizontalLine('⬆️','green');
            } else {
                this.drawLine('🔻','red');
                this.drawLineEnd();
                this.drawHorizontalLine('🔻','red');
            }
        },
        /** draw vertical start line after click
         * @param {string} upOrDown текст на линии старта сделки в графике
         * @param {string} color цвет
         * @param {boolean} lastContract если сделка взята из истории (при обновлении страницы, когда сделка в процессе)
         * */
        drawLine(upOrDown, color, lastContract) {
            let getChart = this.graph.get_one('chart').data;
            let timeLine = this.bid.bidInfo.whenTradeStarts * 1000;
            let startValue = getChart[getChart.length - 1][3];
            this.$store.commit('setValStartContract', startValue);
            this.graph.add('onchart', {
                type: 'Splitters',
                name: 'Data sections',
                data: [
                    [
                        timeLine,
                        'Bid '+upOrDown+':'+startValue,
                    ],
                ],
                settings: {
                    lineColor: color,
                },
            });
        },
        /** draw vertical end line after click*/
        drawLineEnd() {
            let getChart = this.graph.get_one('chart').data;
            //ЗДЕСЬ ОШИБКА - ВРЕМЯ ОКОНЧАНИЯ СДЕЛКИ НУЖНО БРАТЬ В ОТВЕТЕ ВЫЗОВА КОНТРАКТА
            //НАЧАЛО ВРЕМЕНИ ЗАКЛЮЧЕНИЕ КОНТРАКТА ТАК ЖЕ НУЖНО БРАТЬ В ОТВЕТЕ ВЫЗОВА КОНТРАКТА
            // КОГДА НАЧИНАЕТСЯ Starts='+ res['whenTradeStarts']  КОГДА ЗАКАНЧИВАЕТСЯ ' Ends='+ res['whenTradeEnds']
            let timeLine = this.bid.bidInfo.whenTradeEnds * 1000;

            this.graph.add('onchart', {
                type: 'Splitters',
                name: 'Bid',
                data: [
                    [
                        timeLine,
                        'End 🏁' + this.formattedTime(timeLine),
                    ],
                ],
                settings: {
                    lineColor: 'green',
                },
            });
        },
        formattedTime(timeLine) {
            const date = new Date(timeLine);
            // Hours part from the timestamp
            const hours = date.getHours();
            // Minutes part from the timestamp
            const minutes = "0" + date.getMinutes();
            // Seconds part from the timestamp
            const seconds = "0" + date.getSeconds();
            // Will display time in 10:30:23 format
            return hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
        },

        /** draw horizontal line after click*/
        drawHorizontalLine(upOrDown,color) {
            let getChart = this.graph.get_one('chart').data;
            let timeStart = this.bid.bidInfo.whenTradeStarts * 1000;
            let timeEnd = this.bid.bidInfo.whenTradeEnds * 1000;

            console.log('timeStart: ', timeStart, 'timeEnd: ', timeEnd);
            let timeStamp = getChart[getChart.length - 1][0];
            let value = getChart[getChart.length - 1][3];
            let dt =[];
            let temp = [];
            //заполенение массива для горизонтальной линии
            for (let i = timeStart; i <= timeEnd; i+=1000) {
                dt.push([i, value])
            }
            this.graph.add('onchart', {
                type: 'Spline',
                name: 'Bid ' + upOrDown +':' + value,
                data: dt,
                settings: {
                    lineColor: color,
                },
            });
        },
        setHistoryHashFromLS() {
            if (localStorage.getItem('historyHashDeal')) {
                try {
                    let historyHashDealLS = JSON.parse(localStorage.getItem('historyHashDeal'));
                    this.$store.dispatch('setHistoryHashDeal', {val: [...historyHashDealLS], fromLs: true});
                } catch(e) {
                    localStorage.removeItem('historyHashDeal');
                }
            }
            if (localStorage.getItem('historyHashTransfer')) {
                try {
                    let historyHashTransferLS = JSON.parse(localStorage.getItem('historyHashTransfer'));
                    this.$store.dispatch('setHistoryHashTransfer', {val: [...historyHashTransferLS], fromLs: true});
                } catch(e) {
                    // localStorage.removeItem('historyHashTransfer');
                }
            }
            if (localStorage.getItem('historyDeal')) {
                try {
                    let historyDealLS = JSON.parse(localStorage.getItem('historyDeal'));
                    this.$store.dispatch('setHistoryDeal', {val: [...historyDealLS], fromLs: true});
                } catch(e) {
                    localStorage.removeItem('historyDeal');
                }
            }
        },
    },
    created() {
        this.webSocketStart();
    },
    mounted() {
        this.setHistoryHashFromLS();
        window.addEventListener('resize', this.onResize);
        //определяем ширину main контейнера
        this.width = document.querySelector('.el-main').offsetWidth;
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
};
</script>

<style>

</style>
